<template>
  <div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 300px)"
      @header-dragend="headerDragend"
      @row-click="rowClick"
    >
      <el-table-column type="index" label="序号" width="55" />
      <el-table-column label="上传时间" width="200px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.entry_file_time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="描述" min-width="200px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.entry_file_note || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" :show-overflow-tooltip="true">
        <template #default="scope">
          <div class="edit">
            <span
              @click="goDetail(scope.row.entry_url)"
              v-if="scope.row.entry_url != ''"
              style="margin-right: 10px"
              >在线查看</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        small
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <div
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: #323639;
      "
      v-show="showIt"
    >
      <div>
        <el-button v-debounce @click="close" type="danger" style="float: right"
          >关闭</el-button
        >
      </div>
      <iframe
        :src="iframeUrl"
        style="width: 100%; height: 100%"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineExpose } from "vue";
import {
  summary_associate_order,
  inward_associate_order,
} from "@/api/bank/collection.js";
import { ElMessage } from "element-plus";
import { useCache } from "@/hooks/useCache";

const props = defineProps(["activeName", "rowId", "irId"]);

// 表格数据
let tableData = ref([]);

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

const getList = ({ cP = 1, size = 10, tips = false, type }) => {
  let getInfoFn = null;
  switch (type || props.activeName) {
    case "first":
      getInfoFn = summary_associate_order;
      break;
    case "second":
      getInfoFn = inward_associate_order;
      break;
    default:
      break;
  }
  setTimeout(() => {
    getInfoFn({
      vd_id: props.rowId || "",
      ir_id: props.irId || "",
      page: cP,
      size: size,
    }).then((res) => {
      if (res.code == 200) {
        tableData.value = res.data.list;
        totalCount.value = res.data.count;
        if (!tips)
          ElMessage({
            grouping: true,
            showClose: true,
            message: res.msg,
            type: "success",
          });
      }
    });
  }, 0);
};

// 预览地址
const iframeUrl = ref("");
let showIt = ref(false);
const close = () => {
  showIt.value = false;
};

const { wsCache } = useCache();
// 查看详情
const goDetail = (url) => {
  const Auth = wsCache.get("Auth");
  if (!Auth.includes("info_declaration")) return;
  iframeUrl.value = url;
  showIt.value = true;
};

defineExpose({
  getList,
});
</script>

<style scoped>
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit {
  color: var(--el-color-primary);
  cursor: pointer;
  display: flex;
}
</style>
