<template>
    <div>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="校验上传" name="first">
                <LinkTable ref="linkTableRef" :rowId="props.rowId" :irId="props.irId" :activeName="activeName"/>
            </el-tab-pane>
            <el-tab-pane label="界面上传" name="second">
                <LinkTable ref="linkTableRef2" :rowId="props.rowId" :irId="props.irId" :activeName="activeName"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script setup>
import { ref, defineProps, defineExpose } from 'vue'
import LinkTable from './linkTable'

const props = defineProps(['rowId','irId'])

const activeName = ref('first')

const linkTableRef = ref(null)
const linkTableRef2 = ref(null)



const handleClick = (tab) => {
    if(tab.props.name == 'first'){
        linkTableRef.value.getList({ cP: 1, size: 10, tips: true, type: 'first' })
    }else{
        linkTableRef2.value.getList({ cP: 1, size: 10, tips: true, type: 'second' })
    }
}

const initTable = () => {
    activeName.value = 'first'
    linkTableRef.value.getList({ cP: 1, size: 10, tips: true, type: 'first' })
}

defineExpose({
    initTable
})

</script>

<style scoped>
:deep(.el-tabs__nav-wrap::after){
    background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}
</style>