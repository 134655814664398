<template>
    <div class="content">
        <div v-if="props.type == 1">
            <div style="margin:10px 0 50px 0">是否批量审核选中的核销单？</div>
        </div>
        <div v-if="props.type == 2 || props.type == 4">
            <div><span style="margin-right:10px">审核结果：</span>审核不通过</div>
            <div class="flex" style="margin:20px 0">
                <div style="width:90px">原因描述：</div>
                <el-input v-model="note" :rows="4" type="textarea"/>
            </div>
        </div>
        <div v-if="props.type == 3">
            <div>客户：{{ props.infoDetail.ent_name }}</div>
            <div style="margin:15px 0">
                <span>收汇编号: {{ props.infoDetail.inward_remittance_no }}</span>
                <span style="margin-left:20px">核销流水号: {{ props.infoDetail.verification_no }}</span>
            </div>
            <div>
                <span>收款日期: {{ props.infoDetail.payment_date }}</span>
                <span style="margin-left:20px">核销经办日期: {{ props.infoDetail.verification_time }}</span>
            </div>
            <div style="margin:20px 0 30px 0;color:#020079;">
                <span>当前核销外汇金额：</span>
                <span style="font-size:18px;font-weight:700;"><span style="margin-right:5px">{{props.infoDetail.currency}}</span>{{ props.infoDetail.verification_amount }}</span>
            </div>
        </div>
        <div style="text-align:right">
            <el-button v-debounce type="primary" @click="$emit('submit',note)">确认</el-button>
            <el-button v-debounce @click="$emit('cancel')">取消</el-button>
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'

defineEmits(['cancel','submit'])
const props = defineProps(['type','infoDetail'])

const note = ref("")
</script>

<style scoped>
.content{
    margin-top: -20px;
    margin-left: 20px;
}
.flex{
    display:flex
}
</style>