import { useAxios } from '@/hooks/useAxios'

const request = useAxios()

// 核销业务列表
export const list_verification = (data) => {
    return request.post({
        url: '/custom/remittance/list_verification',
        data,
    })
}

// 获取货币
export const getCurrency = () => {
    return request.get({
        url: '/getCurrencyCode',
    })
}

// 管理端-汇入汇款页面
export const info_verification = (data) => {
    return request.post({
        url: '/custom/remittance/info_verification',
        data,
    })
}

// 核销详情
export const list_verification_elist = (data) => {
    return request.post({
        url: '/custom/remittance/list_verification_elist',
        data,
    })
}

// 关联关单-校验上传
export const summary_associate_order = (data) => {
    return request.post({
        url: '/custom/remittance/summary_associate_order',
        data,
    })
}

// 关联关单-界面上传
export const inward_associate_order = (data) => {
    return request.post({
        url: '/custom/remittance/inward_associate_order',
        data,
    })
}

// 操作记录
export const operator_record = (data) => {
    return request.post({
        url: '/custom/remittance/operator_record',
        data,
    })
}

// 审核核销业务
export const examine_verification = (data) => {
    return request.post({
        url: '/custom/remittance/examine_verification',
        data,
    })
}