<template>
  <div>
    <div style="margin: 10px 0">
      <el-button
        v-debounce
        v-disabled="{ name: 'copy_elist_no' }"
        type="primary"
        @click="copyAll"
        >批量复制核销单号</el-button
      >
      <span style="float: right">
        <span v-show="showTable" @click="showTable = false" class="myBtn"
          >点击收起<el-icon class="el-icon--right"><ArrowUp /></el-icon
        ></span>
        <span v-show="!showTable" @click="showTable = true" class="myBtn"
          >点击展开<el-icon class="el-icon--right"><ArrowDown /></el-icon
        ></span>
      </span>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 340px)"
      v-show="showTable"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="核销金额" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.verification_amount || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="100px" show-overflow-tooltip>
        <template v-slot:header>
          <div style="display: flex; align-items: center">
            <div>币制</div>
            <div class="tableHeader">
              <el-popover
                @hide="onHide"
                placement="bottom-end"
                :width="300"
                trigger="click"
              >
                <template #reference>
                  <el-icon color="#1890ff" size="18"><Search /></el-icon>
                </template>
                <div style="display: flex">
                  <el-select
                    v-model="search_param.currency"
                    placeholder="全部"
                    style="width: 100%"
                    filterable
                    clearable
                    :teleported="false"
                  >
                    <el-option
                      v-for="item in props.currencies"
                      :key="item.currency_code"
                      :label="item.currency_Chinese_name"
                      :value="item.currency_code"
                    />
                  </el-select>
                  <el-button
                    v-debounce
                    type="primary"
                    @click="getList({ cP: 1, size: pageSize })"
                    style="margin-left: 10px"
                    >搜索</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-slot:default="scope">
          <div>{{ scope.row.currency || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="130px" show-overflow-tooltip>
        <template v-slot:header>
          <div style="display: flex; align-items: center">
            <div>商品总额</div>
            <div class="tableHeader">
              <el-popover
                @hide="onHide"
                placement="bottom-end"
                :width="300"
                trigger="click"
              >
                <template #reference>
                  <el-icon color="#1890ff" size="18"><Search /></el-icon>
                </template>
                <div style="display: flex">
                  <el-input
                    v-model="search_param.total_amount"
                    clearable
                    type="number"
                  ></el-input>
                  <el-button
                    v-debounce
                    type="primary"
                    @click="getList({ cP: 1, size: pageSize })"
                    style="margin-left: 10px"
                    >搜索</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-slot:default="scope">
          <div>{{ scope.row.total_amount || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="140px" show-overflow-tooltip>
        <template v-slot:header>
          <div style="display: flex; align-items: center">
            <div>生产销售企业</div>
            <div class="tableHeader">
              <el-popover
                @hide="onHide"
                placement="bottom-end"
                :width="300"
                trigger="click"
              >
                <template #reference>
                  <el-icon color="#1890ff" size="18"><Search /></el-icon>
                </template>
                <div style="display: flex">
                  <el-input
                    v-model="search_param.owner_name"
                    clearable
                  ></el-input>
                  <el-button
                    v-debounce
                    type="primary"
                    @click="getList({ cP: 1, size: pageSize })"
                    style="margin-left: 10px"
                    >搜索</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-slot:default="scope">
          <div>{{ scope.row.owner_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="120px" show-overflow-tooltip>
        <template v-slot:header>
          <div style="display: flex; align-items: center">
            <div>清单号</div>
            <div class="tableHeader">
              <el-popover
                @hide="onHide"
                placement="bottom-end"
                :width="300"
                trigger="click"
              >
                <template #reference>
                  <el-icon color="#1890ff" size="18"><Search /></el-icon>
                </template>
                <div style="display: flex">
                  <el-input v-model="search_param.invt_no" clearable></el-input>
                  <el-button
                    v-debounce
                    type="primary"
                    @click="getList({ cP: 1, size: pageSize })"
                    style="margin-left: 10px"
                    >搜索</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-slot:default="scope">
          <div>{{ scope.row.invt_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="运单号" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.logistics_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.ent_order_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="140px" show-overflow-tooltip>
        <template v-slot:header>
          <div style="display: flex; align-items: center">
            <div>汇总申请编号</div>
            <div class="tableHeader">
              <el-popover
                @hide="onHide"
                placement="bottom-end"
                :width="300"
                trigger="click"
              >
                <template #reference>
                  <el-icon color="#1890ff" size="18"><Search /></el-icon>
                </template>
                <div style="display: flex">
                  <el-input v-model="search_param.esb_no" clearable></el-input>
                  <el-button
                    v-debounce
                    type="primary"
                    @click="getList({ cP: 1, size: pageSize })"
                    style="margin-left: 10px"
                    >搜索</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-slot:default="scope">
          <div>{{ scope.row.esb_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="结关时间" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.clearanceTime || "暂无" }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block" v-show="showTable">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        small
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <div
      style="
        text-align: right;
        color: #020079;
        font-size: 18px;
        font-weight: 700;
      "
    >
      <span style="font-size: 14px">当前核销单合计外汇金额：</span>
      <span style="margin-right: 5px">{{ tableData[0]?.currency }}</span>
      <span>{{ sum_amount }}</span>
    </div>
    <!-- 批量复制 -->
    <el-dialog v-model="dialogVisible" width="40%">
      <!-- 头部样式直接写方便下次移动 -->
      <div
        style="
          margin-top: -20px;
          display: flex;
          justify-content: left;
          align-items: center;
        "
      >
        <img
          src="@/assets/images/tips.png"
          alt=""
          style="width: 20px; margin-right: 5px"
        />提示
      </div>
      <!-- 内容部分 -->
      <div style="margin: 20px 50px">
        <div style="margin-bottom: 10px">
          批量选中的核销单号将生成用指定间隔符号的文本格式，例如:
        </div>
        <div style="margin-bottom: 10px">YTO938749238,YTO938749666</div>
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            justify-content: left;
            align-items: center;
          "
        >
          <div style="margin-right: 10px">请选择需要复制的单号</div>
          <el-select v-model="copyType" clearable>
            <el-option
              v-for="item in CopyInfoOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <el-radio v-model="copyConfig.char_type" label="1">逗号</el-radio>
        <el-radio v-model="copyConfig.char_type" label="2">换行符</el-radio>
        <el-radio v-model="copyConfig.char_type" label="3">空格</el-radio>
        <el-radio v-model="copyConfig.char_type" label="4">斜杠</el-radio>
        <div style="margin: 10px 0">
          <el-button v-debounce type="primary" @click="produceCopy"
            >开始生成</el-button
          >
        </div>
        <div>
          <el-input
            type="textarea"
            v-model="copyConfig.text_watch"
            :rows="9"
          ></el-input>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-debounce type="primary" @click="copyButton">
            复制结果
          </el-button>
          <el-button v-debounce @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, defineExpose } from "vue";
import { Search, ArrowUp, ArrowDown } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { list_verification_elist } from "@/api/bank/collection.js";
import useClipboard from "vue-clipboard3";

const props = defineProps(["rowId", "currencies"]);

//查询参数对象
const search_param = reactive({
  vd_id: "",
  currency: "",
  total_amount: "",
  owner_name: "",
  invt_no: "",
  esb_no: "",
  page: 1,
  size: 10,
});

// 折叠表格
const showTable = ref(true);

// 表格数据
let tableData = ref([]);
const sum_amount = ref("");
//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

const onHide = () => {
  Object.keys(search_param).forEach((key) => (search_param[key] = ""));
};

//获得可归集的清单数据列表
const getList = ({ cP = 1, size = 10, tips = false, id }) => {
  currentPage.value = cP;
  pageSize.value = size;
  list_verification_elist({
    vd_id: id || props.rowId || "",
    currency: search_param.currency,
    total_amount: Number(search_param.total_amount) || "",
    owner_name: search_param.owner_name,
    invt_no: search_param.invt_no,
    esb_no: search_param.esb_no,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list || [];
      totalCount.value = res.data.count;
      sum_amount.value = res.data.sum_amount;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
  });
};

const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
};

const copyAll = () => {
  if (!multipleSelection.value.length)
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择需要复制的核销单号",
      type: "warning",
    });
  dialogVisible.value = true;
};

const dialogVisible = ref(false);
const copyConfig = reactive({
  char_type: "",
  text_watch: "",
});
//批量复制框下拉框信息
const copyType = ref();
const CopyInfoOptions = ref([
  { value: "invt_no", label: "清单编号" },
  { value: "logistics_no", label: "运单编号" },
  { value: "ent_order_no", label: "订单编号" },
  { value: "esb_no", label: "汇总申请编号" },
]);
//开始生成按钮
const produceCopy = () => {
  if (!copyType.value || !copyConfig.char_type)
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择复制的单号类型及方式",
      type: "warning",
    });
  const select_copy = multipleSelection.value?.map(
    (item) => item[copyType.value]
  );
  const copyText = select_copy.join(",");
  let newText = "";
  switch (copyConfig.char_type) {
    case "1":
      copyConfig.text_watch = copyText;
      break;
    case "2":
      newText = copyText.split(",").join("\n");
      copyConfig.text_watch = newText;
      break;
    case "3":
      newText = copyText.split(",").join(" ");
      copyConfig.text_watch = newText;
      break;
    case "4":
      newText = copyText.split(",").join("/");
      copyConfig.text_watch = newText;
      break;
  }
};

// 复制功能
const { toClipboard } = useClipboard();
const copyButton = async () => {
  try {
    await toClipboard(copyConfig.text_watch);
    ElMessage({
      grouping: true,
      showClose: true,
      message: "复制成功",
      type: "success",
    });
  } catch (e) {
    console.error(e);
  }
};

const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};

onMounted(async () => {
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
});

defineExpose({
  getList,
});
</script>

<style scoped>
.myBtn {
  border: 1px solid var(--el-color-primary);
  border-radius: 20px;
  color: var(--el-color-primary);
  padding: 5px 15px;
  cursor: pointer;
  user-select: none;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
</style>
