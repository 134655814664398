<template>
  <div class="check">
    <div class="title">
      <div class="title_left">
        <div class="flex">
          <div class="flexBox">
            <div class="flexTitle">收汇编号</div>
            <el-input v-model="search_param.inward_remittance_no"></el-input>
          </div>
          <div class="flexBox">
            <div class="flexTitle" style="width: 130px">汇款人名址</div>
            <el-input v-model="search_param.pay_name_addr"></el-input>
          </div>
          <div class="flexBox">
            <div class="flexTitle">客户名称</div>
            <el-input v-model="search_param.ent_name"></el-input>
          </div>
          <div class="flexBox" style="margin-right: 20px">
            <div class="flexTitle" style="width: 190px">核销经办流水号</div>
            <el-input v-model="search_param.verification_no"></el-input>
          </div>
        </div>
        <div class="flex">
          <div class="flexBox">
            <div class="flexTitle">审核状态</div>
            <el-select
              v-model="search_param.examine_status"
              multiple
              collapse-tags
              collapse-tags-tooltip
              placeholder="全部状态"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in auditOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="flexBox">
            <div class="flexTitle" style="width: 130px">币制</div>
            <el-select
              v-model="search_param.currency"
              multiple
              collapse-tags
              collapse-tags-tooltip
              placeholder="全部"
              style="width: 100%"
              filterable
              clearable
            >
              <el-option
                v-for="item in currencies"
                :key="item.currency_code"
                :label="item.currency_Chinese_name"
                :value="item.currency_code"
              />
            </el-select>
          </div>
          <div class="flexBox" style="flex: 2; margin-right: 80px">
            <div class="flexTitle">收款日期</div>
            <el-date-picker
              v-model="search_param.payment_date"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="title_right">
        <el-button
          v-debounce
          type="primary"
          style="margin-left: auto"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
      </div>
    </div>
    <div style="margin: 5px 0 15px 0">
      <el-button
        v-debounce
        type="primary"
        @click="operateAll(1)"
        v-disabled="{ name: 'examine_verification' }"
        >批量通过</el-button
      >
      <el-button
        v-debounce
        style="margin: 0 10px"
        @click="operateAll(2)"
        v-disabled="{ name: 'examine_verification' }"
        >批量不通过</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 300px)"
      @sort-change="tableSort"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange1"
      @row-click="rowClick"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="审核状态" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.examine_status || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="客户名称" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.ent_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="核销业务流水号"
        min-width="130px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.verification_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="收款日期" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.payment_date || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="币制" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.currency || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="外汇金额"
        min-width="120px"
        sortable="custom"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.remittance_amount || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="汇款人名址"
        min-width="100px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.pay_name_addr || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="业务状态" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.business_status || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="款项性质" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.currency_kind || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="收汇编号" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.inward_remittance_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="汇入机构" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.in_institution || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="收款人账号（付方提供）"
        min-width="180px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.gain_account || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="收款人名称（中文）"
        min-width="160px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.gain_cn_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="收款人名称（英文）"
        min-width="160px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.gain_eng_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="汇款项名址"
        min-width="100px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.remittance_bank_addr || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="汇款人账号"
        min-width="100px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.in_account || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="汇入分行" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.in_sec_institution || "暂无" }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>汇入汇款核销详情</span>
          </span>
          <span
            style="float: right"
            v-show="infoDetail.examine_status == '待审核'"
          >
            <el-button
              v-debounce
              type="primary"
              @click="operateAll(3)"
              v-disabled="{ name: 'examine_verification' }"
              >通过</el-button
            >
            <el-button
              v-debounce
              style="margin-left: 15px"
              @click="operateAll(4)"
              v-disabled="{ name: 'examine_verification' }"
              >不通过</el-button
            >
          </span>
        </div>
        <div style="margin: 20px 0 10px 0">
          <span>客户名称：{{ infoDetail.ent_name }}</span>
          <span style="margin: 0 40px">经办状态：{{ infoDetail.status }}</span>
          <span>审核状态：{{ infoDetail.examine_status }}</span>
          <span style="margin: 0 40px"
            >收款日期：{{ infoDetail.payment_date }}</span
          >
          <span>汇款人名址：{{ infoDetail.pay_name_addr }}</span>
        </div>
        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane label="经办概要" name="first">
            <Summary :infoDetail="infoDetail" />
          </el-tab-pane>
          <el-tab-pane label="核销详情" name="second">
            <Detail ref="DetailRef" :rowId="rowId" :currencies="currencies" />
          </el-tab-pane>
          <el-tab-pane label="关联关单" name="third">
            <Link ref="LinkRef" :rowId="rowId" :irId="irId" />
          </el-tab-pane>
          <el-tab-pane label="操作记录" name="four">
            <Record :activitiesInfo="activitiesInfo" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    <el-dialog
      v-model="dialogVisible"
      width="530px"
      :show-close="false"
      :title="dialogConfig.title"
    >
      <DialogMessage
        :type="dialogConfig.type"
        :infoDetail="infoDetail"
        @cancel="cancel"
        @submit="submit"
      />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "importExchangeQuota",
};
</script>

<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  list_verification,
  getCurrency,
  info_verification,
  operator_record,
  examine_verification,
} from "@/api/bank/collection.js";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import { useCache } from "@/hooks/useCache";
import { ElMessage } from "element-plus";
import Summary from "./components/summary.vue";
import Detail from "./components/detail.vue";
import Link from "./components/link.vue";
import Record from "./components/record.vue";
import DialogMessage from "./components/dialogMessage";

//查询参数对象
const search_param = reactive({
  inward_remittance_no: "",
  pay_name_addr: "",
  ent_name: "",
  verification_no: "",
  examine_status: [],
  currency: [],
  payment_date: [],
  order_by_amount: null,
  page: 1,
  size: 10,
});

// 重置功能
const reset = () => {
  (search_param.inward_remittance_no = ""),
    (search_param.pay_name_addr = ""),
    (search_param.ent_name = ""),
    (search_param.verification_no = ""),
    (search_param.examine_status = []),
    (search_param.currency = []),
    (search_param.payment_date = []),
    (search_param.order_by_amount = null);
};

//审核状态选择下拉框信息
const auditOptions = ref([
  { value: 2, label: "待审核" },
  { value: 3, label: "审核通过" },
  { value: 4, label: "审核不通过" },
]);

// 所有币值下拉框信息
let currencies = ref([]);
// 发起请求获得币值信息
const getCurrencys = async () => {
  const res = await getCurrency();
  if (res) {
    currencies.value = res.data;
  }
};

// 表格数据
let tableData = ref([]);
let multipleSelection1 = ref([]);
const handleSelectionChange1 = (val) => {
  multipleSelection1.value = val;
};

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

//获得可归集的清单数据列表1
const getList = ({ cP = 1, size = 10, tips = false }) => {
  currentPage.value = cP;
  pageSize.value = size;
  list_verification({
    inward_remittance_no: search_param.inward_remittance_no,
    pay_name_addr: search_param.pay_name_addr,
    ent_name: search_param.ent_name,
    verification_no: search_param.verification_no,
    examine_status: search_param.examine_status || [],
    currency: search_param.currency || [],
    payment_date: search_param.payment_date || [],
    order_by_amount: search_param.order_by_amount,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.result;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
    totalCount.value = res.data.count;
  });
};

const headerDragend = (row, column, cell, event) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};

const infoDetail = ref({});
const getInfoVerification = (id) => {
  info_verification({
    vd_id: id,
  }).then((res) => {
    infoDetail.value = res.data.result;
    irId.value = res.data.result.ir_id;
    if (LinkRef.value) LinkRef.value.initTable();
    getOperatorRecord(irId.value);
  });
};

const activitiesInfo = ref([]);
const getOperatorRecord = (id) => {
  operator_record({
    ir_id: id,
  }).then((res) => (activitiesInfo.value = res.data.info));
};

let DetailRef = ref(null);
let LinkRef = ref(null);

const rowId = ref("");
const irId = ref("");

const { wsCache } = useCache();
const rowClick = async (row) => {
  const Auth = wsCache.get("Auth");
  if (!Auth.includes("info_verification")) return;
  rowId.value = row.vd_id;
  await getInfoVerification(row.vd_id);
  showDrawer.value = true;
  if (DetailRef.value)
    DetailRef.value.getList({ cP: 1, size: 10, tips: true, id: row.vd_id });
};

const tableSort = (column) => {
  switch (column.order) {
    case "ascending":
      search_param.order_by_amount = 2;
      break;
    case "descending":
      search_param.order_by_amount = 1;
      break;
    case null:
      search_param.order_by_amount = null;
      break;
    default:
      break;
  }
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

const activeName = ref("first");
const showDrawer = ref(false);

const dialogVisible = ref(false);
const dialogConfig = reactive({
  title: "提示",
  type: 4,
});
const operateAll = (type) => {
  if ((type == 1 || type == 2) && multipleSelection1.value.length == 0)
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择需要操作的核销单",
      type: "warning",
    });
  if (
    (type == 1 || type == 2) &&
    multipleSelection1.value.some((item) => item.examine_status != "待审核")
  )
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择状态为待审核的单",
      type: "warning",
    });
  dialogConfig.type = type;
  if (type == 1 || type == 3) dialogConfig.title = "审批信息确认";
  if (type == 2 || type == 4) dialogConfig.title = "请填写不通过原因";
  dialogVisible.value = true;
};

const cancel = () => (dialogVisible.value = false);
const submit = (note) => {
  let vd_ids = [];
  if (dialogConfig.type == 1 || dialogConfig.type == 2)
    vd_ids = multipleSelection1.value.map((item) => item.vd_id);
  if (dialogConfig.type == 3 || dialogConfig.type == 4) vd_ids = [rowId.value];
  examine_verification({
    type: dialogConfig.type == 1 || dialogConfig.type == 3 ? 1 : 2,
    vd_ids,
    note,
  }).then((res) => {
    dialogVisible.value = false;
    showDrawer.value = false;
    getList({ cP: currentPage.value, size: pageSize.value, tips: true });
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  });
};

onMounted(async () => {
  await getCurrencys();
  // getList({ cP: currentPage.value, size: pageSize.value, tips: true })
});
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
  margin: 0 10px;
}
.title {
  display: flex;
}
.title_left {
  flex: 1;
}
.title_right {
  float: right;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
.edit {
  color: var(--el-color-primary);
  cursor: pointer;
  display: flex;
}

.tableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
:deep(.el-dialog__title) {
  font-size: 16px;
  font-weight: 400;
}

/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
